.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.container p {
    font-size: 2rem;
    font-weight: 700;
    margin-top: 2rem;
    text-transform: capitalize;
}

.btn-group {
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 1rem;
}

.btn-group button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    background-color: #f0f0f0;
    color: #000;
    font-size: 18px;
    font-weight: 700;
 padding: 12px 20px;
    cursor: pointer;
}

.btn-group button:hover {
    background-color: #e0e0e0;
}
/* fonts import */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

/* root variables */
:root {
  --primary-color: #fcfc62;
}

/* body, html and root imports */
*,
body,
html {
  font-family: "Inter", sans-serif;
}

body {
  font-size: 1.6rem;
  background-color: var(--black);
  color: var(--gray-3);
}

a {
  color: var(--primary-color);
}

/* --------------------------------------------------------------- */
main {
  height: 90vh;
  width: 100vw;
  display: flex;
}

.screen-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 92.5vw;
  /* height: 90vh; */
  height: calc(100vh - 70px);
  padding: 0 4rem;
}

/* btn group */
.btn_group {
  display: flex;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1040;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(5px);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #3d3d3d7e;
  border-radius: 15px;
  padding: 5rem;
  min-height: 50vh;
  width: 100%;
  max-width: 60vw;
  backdrop-filter: blur(8px);
}

.modal-main {
  padding-top: 3rem;
  max-height: 70vh;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-bottom: 1.5rem;
  color: white;
}

.modal-main::-webkit-scrollbar {
  display: none;
}

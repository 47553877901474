* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  /* (0 ,0, 100)  */
  --white: #fff;
  /* (0 ,0, 90)  */
  --gray-1: #e6e6e6;
  /* (0 ,0, 80)  */
  --gray-2: #ccc;
  /* (0 ,0, 70)  */
  --gray-3: #b3b3b3;
  /* (0 ,0, 60)  */
  --gray-4: #999;
  /* (0 ,0, 50)  */
  --gray-5: #808080;
  /* (0 ,0, 40)  */
  --gray-6: #666;
  /* (0 ,0, 30)  */
  --gray-7: #4d4d4d;
  /* (0 ,0, 20)  */
  --gray-8: #333;
  /* (0 ,0, 10)  */
  --gray-9: #1a1a1a;
  /* (0 ,0, 0)  */
  --black: #000;

  --bright-red: #eb5757;
  --dark-red: #660101;
}

html {
  /* so that font size is 10px for 1rem */
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  position: relative;
  overflow-x: hidden;
}

ul,
ol {
  list-style: none;
}

span {
  display: inline-block;
}

a {
  text-decoration: none;
}

svg,
img {
  vertical-align: middle;
}

button {
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

input:focus-visible,
textarea:focus-visible,
select:focus-visible,
button:focus-visible {
  outline: none;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.action_button_cell {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

option {
  color: black;
}

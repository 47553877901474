.small_sidebar_item {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.small_sidebar_item button {
  width: 7.5vw;
  height: 7.5vw;
  color: var(--primary-color);
}

.small_sidebar_item button path {
  stroke: var(--primary-color);
  color: var(--primary-color);
}

.small_sidebar_item button:hover path {
  stroke: var(--gray-8);
  color: var(--gray-8);
}

.small_sidebar_item button:hover {
  background-color: var(--primary-color);
  border-radius: 0.8vh;
  color: var(--gray-8);
  stroke: var(--gray-8);
}

.small_sidebar_item a.active > button {
  background-color: var(--primary-color);
  border-radius: 0.8vh;
  color: var(--gray-8) !important;
  stroke: var(--gray-8);
}

.small_sidebar_item a.active button path {
  color: var(--gray-8);
  stroke: var(--gray-8);
}
.small_sidebar_item button svg {
  width: 3.2vh;
  height: 3.2vh;
}
/* .small_sidebar_item button svg > g > path:nth-child(2) {
  fill: var(--primary-color);
} */

.small_sidebar_item button:hover svg > g > path:nth-child(2) {
  fill: var(--gray-8);
}

.small_sidebar_item button:hover svg {
  stroke: var(--primary-color);
}

/* .small_sidebar_item .active svg > g > path:nth-child(2) {
  fill: var(--white);
} */

.file_upload_input[type="file"] {
  display: none;
}
.preview_image {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  width: 100%;
}
.preview_image img {
  max-width: 100%;
  object-fit: cover;
}
.uploadArea {
  background-color: transparent;
  transition: all linear 0.2s;
}
.uploadArea:hover {
  background-color: rgb(224, 219, 219);
}

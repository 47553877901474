.form_header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.9rem;
  margin-bottom: 1rem;
}
.form_header .btn {
  border: 1px solid #000;
  color: #000;
  border-radius: 8px;
  font-size: 1.6rem;
  font-weight: bold;
  padding: 0.5rem 1rem;
}
.form_header .btn.active {
  background: #000;
  color: #fff;
  border-radius: 8px;
  font-size: 1.6rem;
  font-weight: bold;
  padding: 0.5rem 1rem;
}

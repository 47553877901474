.delete_item {
    padding: 5rem;
  }
  .delete_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .delete_content h1 {
    /* color: #fff; */
    font-family: Plus Jakarta Sans;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .delete_content p {
    /* color: #fff; */
    font-family: Plus Jakarta Sans;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 1.5rem;
  }
  .approve_container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    gap: 2rem;
  }
  .approve_content button{
      border-radius: 5px;
      border: 1px solid #1c1b1b;
      font-family: Plus Jakarta Sans;
      font-size: 1.8rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding: 1.2rem 4.6rem;
  }
  .approve_container .cancel {
      color: #b92521;
  }
  
  .approve_container .cancel:hover {
    background-color: #1b1818;
    color: #FFF;
  }
  
  .approve_container .approve {
      color: #FFF;
      background: #212121;
  }
  .approve_container .approve:hover{
      border: 1px solid #9ebdef;
      background-color: var(--primary-color);
  }
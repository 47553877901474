#register{
    
}
.form_wrapper .upload_file_wrapper {
    display: block;
    margin-bottom: 2.4rem;
}
.register .input_field_label {
    color: var(--gray-1);
    display: block;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 2rem;
    margin-bottom: 1.4rem;
    text-transform: capitalize;
}
.register .input_field {
    background-color: rgba(0,0,0,.2);
    border: 1px solid transparent;
    border-radius: 5px;
    color: var(--gray-1);
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.8rem;
    margin-bottom: 2.4rem;
    padding: 1.5rem 2rem;
    width: 100%;
}
.register .input_field:disabled {
    color: var(--gray-4);
}

.input_field::placeholder {
    color: var(--gray-4);
}

.register .preview_image {
    grid-gap: 1.5rem;
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(2,1fr);
    width: 100%;
}
.register .preview_image img {
    height: 12.5rem;
    object-fit: cover;
    width: 100%;
}
.register .submit {
    background-color: #f9bd34;
    color: var(--gray-8);
}
.register .submit:hover {
    background-color: #e6e6e6;
}
